.homepage-desktop {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.homepage-desktop .div {
  background-color: #ffffff;
  height: 8184px;
  overflow: hidden;
  position: relative;
  width: 1920px;
}

.homepage-desktop .layer {
  height: 31px;
  left: 747px;
  position: absolute;
  top: 817px;
  width: 427px;
}

.homepage-desktop .overlap {
  height: 145px;
  left: 1165px;
  position: absolute;
  top: 962px;
  width: 180px;
}

.homepage-desktop .img {
  height: 144px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 136px;
}

.homepage-desktop .group {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 69px;
  left: 111px;
  position: absolute;
  top: 0;
  width: 69px;
}

.homepage-desktop .vector {
  height: 30px;
  left: 20px;
  position: absolute;
  top: 20px;
  width: 35px;
}

.homepage-desktop .overlap-group {
  height: 434px;
  left: 145px;
  position: absolute;
  top: 934px;
  width: 665px;
}

.homepage-desktop .overlap-2 {
  height: 434px;
  left: 0;
  position: absolute;
  top: 0;
  width: 665px;
}

.homepage-desktop .text-wrapper {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 208px;
  white-space: nowrap;
  width: 325px;
}

.homepage-desktop .rectangle {
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 7px 7px 50px #1c7eb51a;
  height: 416px;
  left: 315px;
  position: absolute;
  top: 0;
  width: 312px;
}

.homepage-desktop .p {
  color: #ff0080;
  font-family: "Rubik", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 340px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 208px;
  width: 325px;
}

.homepage-desktop .text-wrapper-2 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 340px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 273px;
  width: 284px;
}

.homepage-desktop .element {
  height: 39px;
  left: 503px;
  position: absolute;
  top: 395px;
  width: 39px;
}

.homepage-desktop .overlap-wrapper {
  height: 141px;
  left: 338px;
  position: absolute;
  top: 28px;
  width: 172px;
}

.homepage-desktop .overlap-3 {
  height: 141px;
  position: relative;
}

.homepage-desktop .layer-2 {
  height: 119px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 144px;
}

.homepage-desktop .group-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 69px;
  left: 103px;
  position: absolute;
  top: 0;
  width: 69px;
}

.homepage-desktop .group-2 {
  height: 31px;
  left: 19px;
  position: absolute;
  top: 19px;
  width: 31px;
}

.homepage-desktop .design-custom {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 247px;
  width: 270px;
}

.homepage-desktop .overlap-4 {
  height: 148px;
  left: 0;
  position: absolute;
  top: 29px;
  width: 180px;
}

.homepage-desktop .layer-3 {
  background-image: url(../../../static/img/vector-62.svg);
  background-size: 100% 100%;
  height: 140px;
  left: 0;
  position: absolute;
  top: 0;
  width: 140px;
}

.homepage-desktop .overlap-5 {
  background-image: url(../../../static/img/vector-63.svg);
  background-size: 100% 100%;
  height: 111px;
  left: 10px;
  position: absolute;
  top: 19px;
  width: 121px;
}

.homepage-desktop .img-wrapper {
  background-image: url(../../../static/img/vector-64.svg);
  background-size: 100% 100%;
  height: 39px;
  left: 65px;
  position: absolute;
  top: 12px;
  width: 43px;
}

.homepage-desktop .group-3 {
  height: 18px;
  left: 10px;
  position: absolute;
  top: 11px;
  width: 25px;
}

.homepage-desktop .overlap-6 {
  background-image: url(../../../static/img/vector-65.svg);
  background-size: 100% 100%;
  height: 39px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 43px;
}

.homepage-desktop .vector-2 {
  height: 11px;
  left: 13px;
  position: absolute;
  top: 21px;
  width: 18px;
}

.homepage-desktop .vector-3 {
  height: 11px;
  left: 16px;
  position: absolute;
  top: 10px;
  width: 11px;
}

.homepage-desktop .vector-wrapper {
  background-image: url(../../../static/img/vector-66.svg);
  background-size: 100% 100%;
  height: 39px;
  left: 65px;
  position: absolute;
  top: 60px;
  width: 43px;
}

.homepage-desktop .vector-4 {
  height: 17px;
  left: 10px;
  position: absolute;
  top: 11px;
  width: 24px;
}

.homepage-desktop .overlap-group-2 {
  background-image: url(../../../static/img/vector-67.svg);
  background-size: 100% 100%;
  height: 39px;
  left: 12px;
  position: absolute;
  top: 60px;
  width: 43px;
}

.homepage-desktop .vector-5 {
  height: 23px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 20px;
}

.homepage-desktop .group-4 {
  height: 16px;
  left: 10px;
  position: absolute;
  top: 11px;
  width: 13px;
}

.homepage-desktop .group-5 {
  height: 24px;
  left: 22px;
  position: absolute;
  top: 7px;
  width: 4px;
}

.homepage-desktop .group-6 {
  height: 13px;
  left: 22px;
  position: absolute;
  top: 14px;
  width: 18px;
}

.homepage-desktop .vector-6 {
  height: 4px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 4px;
}

.homepage-desktop .group-7 {
  height: 6px;
  left: 10px;
  position: absolute;
  top: 8px;
  width: 17px;
}

.homepage-desktop .div-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 69px;
  left: 111px;
  position: absolute;
  top: 79px;
  width: 69px;
}

.homepage-desktop .group-8 {
  height: 31px;
  left: 19px;
  position: relative;
  top: 19px;
  width: 31px;
}

.homepage-desktop .rectangle-2 {
  background-color: #00b9e8;
  border-radius: 3px;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.homepage-desktop .rectangle-3 {
  background-color: #00b9e8;
  border-radius: 3px;
  height: 15px;
  left: 16px;
  position: absolute;
  top: 0;
  width: 15px;
}

.homepage-desktop .rectangle-4 {
  background-color: #00b9e8;
  border-radius: 3px;
  height: 15px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 15px;
}

.homepage-desktop .rectangle-5 {
  background-color: #00b9e8;
  border-radius: 3px;
  height: 15px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 15px;
}

.homepage-desktop .overlap-7 {
  height: 812px;
  left: 286px;
  position: absolute;
  top: 0;
  width: 1697px;
}

.homepage-desktop .overlap-8 {
  height: 812px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1697px;
}

.homepage-desktop .vector-7 {
  height: 812px;
  left: 576px;
  position: absolute;
  top: 0;
  width: 1058px;
}

.homepage-desktop .vector-8 {
  height: 742px;
  left: 576px;
  position: absolute;
  top: 0;
  width: 1058px;
}

.homepage-desktop .navbar {
  height: 20px;
  left: 669px;
  position: absolute;
  top: 35px;
  width: 387px;
}

.homepage-desktop .text-wrapper-3 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.homepage-desktop .text-wrapper-4 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 96px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.homepage-desktop .text-wrapper-5 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 192px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.homepage-desktop .text-wrapper-6 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 327px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
}

.homepage-desktop .transform-online {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 45px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 183px;
  width: 698px;
}

.homepage-desktop .frame {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 619px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 764px;
}

.homepage-desktop .text-wrapper-7 {
  color: #ff0080;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.homepage-desktop .frame-2 {
  align-items: center;
  background-color: #00b9e8;
  border-radius: 9.75px;
  box-shadow: 8px 8px 40px #0406354c;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 1168px;
  overflow: hidden;
  padding: 15px 26px;
  position: absolute;
  top: 20px;
}

.homepage-desktop .text-wrapper-8 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: fit-content;
}

.homepage-desktop .group-9 {
  height: 156px;
  left: 732px;
  position: absolute;
  top: 112px;
  width: 211px;
}

.homepage-desktop .rectangle-6 {
  background-color: #ffffff;
  border-radius: 8px;
  height: 63px;
  left: 148px;
  opacity: 0.1;
  position: absolute;
  top: 0;
  width: 63px;
}

.homepage-desktop .rectangle-7 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 35px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 29px;
  width: 35px;
}

.homepage-desktop .group-10 {
  height: 93px;
  left: 47px;
  position: absolute;
  top: 63px;
  width: 93px;
}

.homepage-desktop .group-11 {
  height: 223px;
  left: 1265px;
  position: absolute;
  top: 128px;
  width: 183px;
}

.homepage-desktop .rectangle-8 {
  background-color: #ffffff;
  border-radius: 8px;
  height: 63px;
  left: 67px;
  opacity: 0.1;
  position: absolute;
  top: 53px;
  width: 63px;
}

.homepage-desktop .rectangle-9 {
  background-color: #ffffff;
  border-radius: 6px;
  height: 35px;
  left: 130px;
  opacity: 0.1;
  position: absolute;
  top: 188px;
  width: 35px;
}

.homepage-desktop .rectangle-10 {
  background-color: #ffffff;
  border-radius: 8px;
  height: 63px;
  left: 68px;
  opacity: 0.1;
  position: absolute;
  top: 121px;
  width: 63px;
}

.homepage-desktop .rectangle-11 {
  background-color: #ffffff;
  border-radius: 8px;
  height: 63px;
  left: 0;
  opacity: 0.1;
  position: absolute;
  top: 121px;
  width: 63px;
}

.homepage-desktop .group-12 {
  height: 53px;
  left: 130px;
  position: absolute;
  top: 0;
  width: 53px;
}

.homepage-desktop .ellipse {
  background: linear-gradient(180deg, rgb(220, 46, 153) 0%, rgb(199.75, 83.23, 176.24) 100%);
  border-radius: 91px;
  height: 182px;
  left: 1515px;
  position: absolute;
  top: 321px;
  width: 182px;
}

.homepage-desktop .vector-9 {
  height: 16px;
  left: 676px;
  position: absolute;
  top: 566px;
  width: 240px;
}

.homepage-desktop .image {
  height: 381px;
  left: 805px;
  object-fit: cover;
  position: absolute;
  top: 201px;
  width: 487px;
}

.homepage-desktop .layer-4 {
  height: 198px;
  left: 733px;
  position: absolute;
  top: 376px;
  width: 166px;
}

.homepage-desktop .isolation-mode {
  height: 87px;
  left: 680px;
  position: absolute;
  top: 486px;
  width: 44px;
}

.homepage-desktop .interviewr-logo {
  height: 78px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 195px;
}

.homepage-desktop .frame-3 {
  align-items: center;
  background-color: #00b9e8;
  border-radius: 9.75px;
  box-shadow: 6px 6px 30px #1c7eb54c;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  overflow: hidden;
  padding: 15px 26px;
  position: absolute;
  top: 460px;
}

.homepage-desktop .text-wrapper-9 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 324px;
  width: 515px;
}

.homepage-desktop .overlap-9 {
  height: 568px;
  left: 203px;
  position: absolute;
  top: 2489px;
  width: 768px;
}

.homepage-desktop .vector-10 {
  height: 172px;
  left: 577px;
  position: absolute;
  top: 397px;
  width: 191px;
}

.homepage-desktop .vector-11 {
  height: 274px;
  left: 0;
  position: absolute;
  top: 0;
  width: 307px;
}

.homepage-desktop .rectangle-12 {
  height: 402px;
  left: 97px;
  position: absolute;
  top: 83px;
  width: 603px;
}

.homepage-desktop .vector-12 {
  height: 72px;
  left: 61px;
  position: absolute;
  top: 120px;
  width: 72px;
}

.homepage-desktop .overlap-10 {
  height: 864px;
  left: 0;
  position: absolute;
  top: 1508px;
  width: 1920px;
}

.homepage-desktop .vector-13 {
  height: 854px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.homepage-desktop .vector-14 {
  height: 854px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 1920px;
}

.homepage-desktop .group-13 {
  height: 98px;
  left: 259px;
  position: absolute;
  top: 254px;
  width: 98px;
}

.homepage-desktop .group-14 {
  height: 98px;
  left: 1562px;
  position: absolute;
  top: 468px;
  width: 98px;
}

.homepage-desktop .rectangle-13 {
  background-color: #ffffff;
  border-radius: 14px;
  height: 218px;
  left: 300px;
  position: absolute;
  top: 300px;
  width: 419px;
}

.homepage-desktop .text-wrapper-10 {
  color: #a1b0c6;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 339px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 365px;
  width: 341px;
}

.homepage-desktop .text-wrapper-11 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 470px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 659px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-12 {
  color: #a1b0c6;
  font-family: "Rubik", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 477px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 681px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-13 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 639px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 181px;
  width: 642px;
}

.homepage-desktop .frame-4 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 905px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 136px;
}

.homepage-desktop .ellipse-2 {
  height: 78px;
  left: 471px;
  position: absolute;
  top: 567px;
  width: 78px;
}

.homepage-desktop .vector-15 {
  height: 23px;
  left: 491px;
  position: absolute;
  top: 517px;
  width: 37px;
}

.homepage-desktop .rectangle-14 {
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 7px 7px 50px #1c7eb533;
  height: 218px;
  left: 750px;
  position: absolute;
  top: 300px;
  width: 419px;
}

.homepage-desktop .this-platform {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 789px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 354px;
  width: 341px;
}

.homepage-desktop .text-wrapper-14 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 920px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 659px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-15 {
  color: #ff0080;
  font-family: "Rubik", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 840px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 681px;
  white-space: nowrap;
}

.homepage-desktop .ellipse-3 {
  height: 178px;
  left: 878px;
  position: absolute;
  top: 524px;
  width: 178px;
}

.homepage-desktop .group-15 {
  height: 198px;
  left: 760px;
  position: absolute;
  top: 310px;
  width: 399px;
}

.homepage-desktop .vector-16 {
  height: 123px;
  left: 898px;
  position: absolute;
  top: 474px;
  width: 137px;
}

.homepage-desktop .rectangle-15 {
  background-color: #ffffff;
  border-radius: 14px;
  height: 218px;
  left: 1200px;
  position: absolute;
  top: 300px;
  width: 419px;
}

.homepage-desktop .text-wrapper-16 {
  color: #a1b0c6;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1239px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 365px;
  width: 341px;
}

.homepage-desktop .text-wrapper-17 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1375px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 659px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-18 {
  color: #a1b0c6;
  font-family: "Rubik", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 1342px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  text-align: center;
  top: 681px;
  white-space: nowrap;
}

.homepage-desktop .ellipse-4 {
  height: 78px;
  left: 1371px;
  position: absolute;
  top: 567px;
  width: 78px;
}

.homepage-desktop .vector-17 {
  height: 23px;
  left: 1391px;
  position: absolute;
  top: 517px;
  width: 37px;
}

.homepage-desktop .group-16 {
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 3px 3px 20px #1c7eb526;
  height: 36px;
  left: 223px;
  position: absolute;
  top: 390px;
  width: 36px;
}

.homepage-desktop .vector-18 {
  height: 10px;
  left: 15px;
  position: absolute;
  top: 13px;
  width: 6px;
}

.homepage-desktop .group-17 {
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: 3px 3px 20px #1c7eb526;
  height: 36px;
  left: 1660px;
  position: absolute;
  top: 390px;
  transform: rotate(180deg);
  width: 36px;
}

.homepage-desktop .vector-19 {
  height: 10px;
  left: 15px;
  position: absolute;
  top: 13px;
  transform: rotate(-180deg);
  width: 6px;
}

.homepage-desktop .vector-20 {
  height: 72px;
  left: 1680px;
  position: absolute;
  top: 229px;
  width: 72px;
}

.homepage-desktop .vector-21 {
  height: 29px;
  left: 1736px;
  position: absolute;
  top: 175px;
  width: 29px;
}

.homepage-desktop .vector-22 {
  height: 33px;
  left: 164px;
  position: absolute;
  top: 197px;
  width: 32px;
}

.homepage-desktop .ellipse-5 {
  background-color: #00b9e8;
  border-radius: 18.88px;
  height: 38px;
  left: 1623px;
  position: absolute;
  top: 202px;
  transform: rotate(-15deg);
  width: 38px;
}

.homepage-desktop .text-wrapper-19 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 825px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1142px;
  width: 287px;
}

.homepage-desktop .text-wrapper-20 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 1165px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1142px;
  width: 254px;
}

.homepage-desktop .text-wrapper-21 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 22px;
  font-weight: 700;
  left: 1505px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1142px;
  white-space: nowrap;
  width: 325px;
}

.homepage-desktop .text-wrapper-22 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 825px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1233px;
  width: 269px;
}

.homepage-desktop .look-back-at-your {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1165px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1207px;
  width: 264px;
}

.homepage-desktop .text-wrapper-23 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 1505px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1181px;
  width: 261px;
}

.homepage-desktop .elevating-dialogues {
  color: transparent;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 1089px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2608px;
}

.homepage-desktop .span {
  color: #00b9e8;
}

.homepage-desktop .text-wrapper-24 {
  color: #040635;
}

.homepage-desktop .frame-5 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 1089px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 2563px;
}

.homepage-desktop .join-us-at {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1089px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 2724px;
  width: 531px;
}

.homepage-desktop .interview-room {
  color: transparent;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 300px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3342px;
}

.homepage-desktop .frame-6 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 300px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 3297px;
}

.homepage-desktop .text-wrapper-25 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 300px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 3458px;
  width: 531px;
}

.homepage-desktop .candidate-management {
  color: transparent;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 300px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 4648px;
}

.homepage-desktop .frame-7 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 300px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 4603px;
}

.homepage-desktop .text-wrapper-26 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 300px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 4764px;
  width: 531px;
}

.homepage-desktop .room-template {
  color: transparent;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 1089px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 3995px;
}

.homepage-desktop .frame-8 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 1089px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 3950px;
}

.homepage-desktop .build-personalized {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1093px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 4107px;
  width: 531px;
}

.homepage-desktop .account-management {
  color: transparent;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 1089px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5301px;
}

.homepage-desktop .frame-9 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 1089px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 5256px;
}

.homepage-desktop .text-wrapper-27 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 1089px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  top: 5417px;
  width: 531px;
}

.homepage-desktop .overlap-11 {
  height: 497px;
  left: 976px;
  position: absolute;
  top: 3215px;
  width: 714px;
}

.homepage-desktop .vector-23 {
  height: 442px;
  left: 34px;
  position: absolute;
  top: 0;
  width: 495px;
}

.homepage-desktop .group-18 {
  height: 98px;
  left: 596px;
  position: absolute;
  top: 17px;
  width: 98px;
}

.homepage-desktop .vector-24 {
  height: 72px;
  left: 59px;
  position: absolute;
  top: 79px;
  width: 42px;
}

.homepage-desktop .vector-25 {
  height: 396px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 542px;
}

.homepage-desktop .screens {
  height: 456px;
  left: 113px;
  object-fit: cover;
  position: absolute;
  top: 30px;
  width: 570px;
}

.homepage-desktop .vector-26 {
  height: 51px;
  left: 612px;
  position: absolute;
  top: 288px;
  width: 103px;
}

.homepage-desktop .vector-27 {
  height: 16px;
  left: 98px;
  position: absolute;
  top: 481px;
  width: 116px;
}

.homepage-desktop .frame-10 {
  height: 341px;
  left: 101px;
  position: absolute;
  top: 148px;
  width: 129px;
}

.homepage-desktop .overlap-12 {
  height: 547px;
  left: 300px;
  position: absolute;
  top: 3862px;
  width: 701px;
}

.homepage-desktop .group-19 {
  height: 98px;
  left: 539px;
  position: absolute;
  top: 331px;
  width: 98px;
}

.homepage-desktop .vector-28 {
  height: 547px;
  left: 100px;
  position: absolute;
  top: 0;
  width: 601px;
}

.homepage-desktop .vector-29 {
  height: 49px;
  left: 205px;
  position: absolute;
  top: 0;
  width: 49px;
}

.homepage-desktop .screens-2 {
  height: 453px;
  left: 64px;
  object-fit: cover;
  position: absolute;
  top: 49px;
  width: 569px;
}

.homepage-desktop .layer-5 {
  height: 24px;
  left: 649px;
  position: absolute;
  top: 288px;
  width: 24px;
}

.homepage-desktop .vector-30 {
  height: 16px;
  left: 4px;
  position: absolute;
  top: 514px;
  width: 116px;
}

.homepage-desktop .frame-11 {
  height: 404px;
  left: 0;
  position: absolute;
  top: 117px;
  width: 215px;
}

.homepage-desktop .overlap-13 {
  height: 563px;
  left: 1000px;
  position: absolute;
  top: 4557px;
  width: 742px;
}

.homepage-desktop .vector-31 {
  height: 493px;
  left: 199px;
  position: absolute;
  top: 70px;
  width: 543px;
}

.homepage-desktop .vector-32 {
  height: 212px;
  left: 0;
  position: absolute;
  top: 243px;
  width: 234px;
}

.homepage-desktop .layer-6 {
  height: 139px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 139px;
}

.homepage-desktop .screens-3 {
  height: 456px;
  left: 34px;
  object-fit: cover;
  position: absolute;
  top: 21px;
  width: 570px;
}

.homepage-desktop .vector-33 {
  height: 13px;
  left: 493px;
  position: absolute;
  top: 129px;
  width: 128px;
}

.homepage-desktop .vector-34 {
  height: 25px;
  left: 461px;
  position: absolute;
  top: 431px;
  width: 237px;
}

.homepage-desktop .frame-12 {
  height: 247px;
  left: 495px;
  position: absolute;
  top: 197px;
  width: 170px;
}

.homepage-desktop .overlap-14 {
  background-image: url(../../../static/img/vector-16.svg);
  background-size: 100% 100%;
  height: 1320px;
  left: 0;
  position: absolute;
  top: 6864px;
  width: 1920px;
}

.homepage-desktop .vector-35 {
  height: 1284px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 1920px;
}

.homepage-desktop .group-20 {
  height: 258px;
  left: 682px;
  position: absolute;
  top: 350px;
  width: 562px;
}

.homepage-desktop .text-wrapper-28 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 18px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 61px;
  width: 521px;
}

.homepage-desktop .text-wrapper-29 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.homepage-desktop .overlap-group-wrapper {
  height: 51px;
  left: 18px;
  position: absolute;
  top: 151px;
  width: 525px;
}

.homepage-desktop .overlap-group-3 {
  height: 51px;
  position: relative;
  width: 521px;
}

.homepage-desktop .rectangle-16 {
  background-color: #ffffff;
  border-radius: 9.75px;
  height: 51px;
  left: 0;
  opacity: 0.23;
  position: absolute;
  top: 0;
  width: 406px;
}

.homepage-desktop .rectangle-17 {
  background-color: #00b9e8;
  border-radius: 0px 9.75px 9.75px 0px;
  box-shadow: 6px 6px 30px #1c7eb54c;
  height: 51px;
  left: 379px;
  position: absolute;
  top: 0;
  width: 142px;
}

.homepage-desktop .text-wrapper-30 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 413px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 15px;
}

.homepage-desktop .text-wrapper-31 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 21px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 15px;
}

.homepage-desktop .our-privacy-policy {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 18px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 215px;
  width: 521px;
}

.homepage-desktop .text-wrapper-32 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
}

.homepage-desktop .text-wrapper-33 {
  text-decoration: underline;
}

.homepage-desktop .text-wrapper-34 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 300px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 788px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-35 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 300px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 757px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-36 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 590px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 788px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-37 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 590px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 757px;
  white-space: nowrap;
}

.homepage-desktop .element-interviewr {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 748px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 1236px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-38 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 22px;
}

.homepage-desktop .text-wrapper-39 {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 890px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 788px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-40 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 890px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 757px;
  white-space: nowrap;
}

.homepage-desktop .yearly-monthly {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1190px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 788px;
}

.homepage-desktop .text-wrapper-41 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1190px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 757px;
  white-space: nowrap;
}

.homepage-desktop .email-ian-interviewr {
  color: #ffffff;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1490px;
  letter-spacing: 0;
  line-height: 40px;
  position: absolute;
  top: 788px;
}

.homepage-desktop .text-wrapper-42 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 16px;
  font-weight: 700;
  left: 1490px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 757px;
  white-space: nowrap;
}

.homepage-desktop .interviewr-logo-dark {
  height: 88px;
  left: 286px;
  object-fit: cover;
  position: absolute;
  top: 1204px;
  width: 219px;
}

.homepage-desktop .ellipse-6 {
  background-color: #ffffff;
  border-radius: 17px;
  height: 34px;
  left: 1496px;
  position: absolute;
  top: 1231px;
  width: 34px;
}

.homepage-desktop .ellipse-7 {
  background-color: #ffffff;
  border-radius: 17px;
  height: 34px;
  left: 1540px;
  position: absolute;
  top: 1231px;
  width: 34px;
}

.homepage-desktop .ellipse-8 {
  background-color: #ffffff;
  border-radius: 17px;
  height: 34px;
  left: 1585px;
  position: absolute;
  top: 1231px;
  width: 34px;
}

.homepage-desktop .vector-36 {
  height: 15px;
  left: 1509px;
  position: absolute;
  top: 1241px;
  width: 7px;
}

.homepage-desktop .vector-37 {
  height: 15px;
  left: 1550px;
  position: absolute;
  top: 1241px;
  width: 15px;
}

.homepage-desktop .vector-38 {
  height: 11px;
  left: 1596px;
  position: absolute;
  top: 1243px;
  width: 14px;
}

.homepage-desktop .overlap-15 {
  height: 865px;
  left: 0;
  position: absolute;
  top: 5875px;
  width: 1920px;
}

.homepage-desktop .vector-39 {
  height: 649px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.homepage-desktop .vector-40 {
  height: 286px;
  left: 0;
  position: absolute;
  top: 357px;
  width: 1920px;
}

.homepage-desktop .group-21 {
  height: 98px;
  left: 1449px;
  position: absolute;
  top: 254px;
  width: 98px;
}

.homepage-desktop .text-wrapper-43 {
  color: #040635;
  font-family: "Rubik", Helvetica;
  font-size: 40px;
  font-weight: 700;
  left: 639px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: 642px;
}

.homepage-desktop .frame-13 {
  align-items: center;
  background-color: #ffe8f3;
  border-radius: 16.5px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 905px;
  overflow: hidden;
  padding: 3px 14px;
  position: absolute;
  top: 79px;
}

.homepage-desktop .text-wrapper-44 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 700px;
  letter-spacing: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 189px;
  width: 521px;
}

.homepage-desktop .rectangle-18 {
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 7px 7px 50px #1c7eb51a;
  height: 562px;
  left: 792px;
  position: absolute;
  top: 303px;
  width: 336px;
}

.homepage-desktop .rectangle-19 {
  background-color: #ff0080;
  border-radius: 0px 0px 20px 20px;
  height: 107px;
  left: 895px;
  position: absolute;
  top: 303px;
  width: 130px;
}

.homepage-desktop .text-wrapper-45 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 932px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 373px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-46 {
  color: #ff0080;
  font-family: "Rubik", Helvetica;
  font-size: 74.7px;
  font-weight: 700;
  left: 925px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 437px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-47 {
  color: #ff0080;
  font-family: "Rubik", Helvetica;
  font-size: 37.4px;
  font-weight: 700;
  left: 896px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 445px;
  white-space: nowrap;
}

.homepage-desktop .frame-14 {
  align-items: center;
  background-color: #ff0080;
  border-radius: 9.75px;
  box-shadow: 6px 6px 30px #ff008033;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 896px;
  overflow: hidden;
  padding: 15px 26px;
  position: absolute;
  top: 760px;
}

.homepage-desktop .diamond {
  height: 40px;
  left: 938px;
  object-fit: cover;
  position: absolute;
  top: 326px;
  width: 45px;
}

.homepage-desktop .text-wrapper-48 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 877px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 546px;
  white-space: nowrap;
}

.homepage-desktop .vector-41 {
  height: 11px;
  left: 854px;
  position: absolute;
  top: 552px;
  width: 15px;
}

.homepage-desktop .custom-widget-tools {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 877px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 579px;
  white-space: nowrap;
}

.homepage-desktop .vector-42 {
  height: 11px;
  left: 854px;
  position: absolute;
  top: 586px;
  width: 15px;
}

.homepage-desktop .text-wrapper-49 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 877px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 609px;
  white-space: nowrap;
}

.homepage-desktop .vector-43 {
  height: 11px;
  left: 854px;
  position: absolute;
  top: 616px;
  width: 15px;
}

.homepage-desktop .text-wrapper-50 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 877px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 639px;
  white-space: nowrap;
}

.homepage-desktop .vector-44 {
  height: 11px;
  left: 854px;
  position: absolute;
  top: 646px;
  width: 15px;
}

.homepage-desktop .text-wrapper-51 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 877px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 672px;
  white-space: nowrap;
}

.homepage-desktop .vector-45 {
  height: 11px;
  left: 854px;
  position: absolute;
  top: 678px;
  width: 15px;
}

.homepage-desktop .text-wrapper-52 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 877px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 702px;
  white-space: nowrap;
}

.homepage-desktop .vector-46 {
  height: 11px;
  left: 854px;
  position: absolute;
  top: 708px;
  width: 15px;
}

.homepage-desktop .rectangle-20 {
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 7px 7px 50px #1c7eb51a;
  height: 562px;
  left: 422px;
  position: absolute;
  top: 303px;
  width: 336px;
}

.homepage-desktop .rectangle-21 {
  background-color: #00b9e8;
  border-radius: 0px 0px 20px 20px;
  height: 107px;
  left: 525px;
  position: absolute;
  top: 303px;
  width: 130px;
}

.homepage-desktop .text-wrapper-53 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 553px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 373px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-54 {
  color: #00b9e8;
  font-family: "Rubik", Helvetica;
  font-size: 74.7px;
  font-weight: 700;
  left: 555px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 437px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-55 {
  color: #00b9e8;
  font-family: "Rubik", Helvetica;
  font-size: 37.4px;
  font-weight: 700;
  left: 526px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 445px;
  white-space: nowrap;
}

.homepage-desktop .frame-15 {
  align-items: center;
  background-color: #00b9e8;
  border-radius: 9.75px;
  box-shadow: 6px 6px 30px #1c7eb54c;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 526px;
  overflow: hidden;
  padding: 15px 26px;
  position: absolute;
  top: 760px;
}

.homepage-desktop .diamond-2 {
  height: 40px;
  left: 570px;
  object-fit: cover;
  position: absolute;
  top: 326px;
  width: 40px;
}

.homepage-desktop .text-wrapper-56 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 507px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 546px;
  white-space: nowrap;
}

.homepage-desktop .vector-47 {
  height: 11px;
  left: 484px;
  position: absolute;
  top: 552px;
  width: 15px;
}

.homepage-desktop .custom-widget-tools-2 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 507px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 579px;
  white-space: nowrap;
}

.homepage-desktop .vector-48 {
  height: 11px;
  left: 484px;
  position: absolute;
  top: 586px;
  width: 15px;
}

.homepage-desktop .text-wrapper-57 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 507px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 609px;
  white-space: nowrap;
}

.homepage-desktop .vector-49 {
  height: 11px;
  left: 484px;
  position: absolute;
  top: 616px;
  width: 15px;
}

.homepage-desktop .text-wrapper-58 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 507px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 639px;
  white-space: nowrap;
}

.homepage-desktop .vector-50 {
  height: 11px;
  left: 484px;
  position: absolute;
  top: 646px;
  width: 15px;
}

.homepage-desktop .text-wrapper-59 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 507px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 672px;
  white-space: nowrap;
}

.homepage-desktop .vector-51 {
  height: 11px;
  left: 484px;
  position: absolute;
  top: 678px;
  width: 15px;
}

.homepage-desktop .text-wrapper-60 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 507px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 702px;
  white-space: nowrap;
}

.homepage-desktop .vector-52 {
  height: 11px;
  left: 484px;
  position: absolute;
  top: 708px;
  width: 15px;
}

.homepage-desktop .rectangle-22 {
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 7px 7px 50px #1c7eb51a;
  height: 562px;
  left: 1162px;
  position: absolute;
  top: 303px;
  width: 336px;
}

.homepage-desktop .rectangle-23 {
  background-color: #c4d3e0;
  border-radius: 0px 0px 20px 20px;
  height: 107px;
  left: 1265px;
  position: absolute;
  top: 303px;
  width: 130px;
}

.homepage-desktop .text-wrapper-61 {
  color: #ffffff;
  font-family: "Rubik", Helvetica;
  font-size: 18px;
  font-weight: 700;
  left: 1288px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 373px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-62 {
  color: #c4d3e0;
  font-family: "Rubik", Helvetica;
  font-size: 74.7px;
  font-weight: 700;
  left: 1319px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 437px;
  white-space: nowrap;
}

.homepage-desktop .text-wrapper-63 {
  color: #c4d3e0;
  font-family: "Rubik", Helvetica;
  font-size: 37.4px;
  font-weight: 700;
  left: 1290px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 445px;
  white-space: nowrap;
}

.homepage-desktop .frame-16 {
  align-items: center;
  background-color: #c4d3e0;
  border-radius: 9.75px;
  box-shadow: 6px 6px 30px #1c7eb51a;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 1266px;
  overflow: hidden;
  padding: 15px 26px;
  position: absolute;
  top: 760px;
}

.homepage-desktop .text-wrapper-64 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1247px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 546px;
  white-space: nowrap;
}

.homepage-desktop .vector-53 {
  height: 11px;
  left: 1224px;
  position: absolute;
  top: 552px;
  width: 15px;
}

.homepage-desktop .text-wrapper-65 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1247px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 579px;
  white-space: nowrap;
}

.homepage-desktop .vector-54 {
  height: 11px;
  left: 1224px;
  position: absolute;
  top: 586px;
  width: 15px;
}

.homepage-desktop .text-wrapper-66 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1247px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 609px;
  white-space: nowrap;
}

.homepage-desktop .vector-55 {
  height: 11px;
  left: 1224px;
  position: absolute;
  top: 616px;
  width: 15px;
}

.homepage-desktop .text-wrapper-67 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1247px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 639px;
  white-space: nowrap;
}

.homepage-desktop .vector-56 {
  height: 11px;
  left: 1224px;
  position: absolute;
  top: 646px;
  width: 15px;
}

.homepage-desktop .text-wrapper-68 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1247px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 672px;
  white-space: nowrap;
}

.homepage-desktop .vector-57 {
  height: 11px;
  left: 1224px;
  position: absolute;
  top: 678px;
  width: 15px;
}

.homepage-desktop .text-wrapper-69 {
  color: #4a4a4a;
  font-family: "Quicksand", Helvetica;
  font-size: 14px;
  font-weight: 500;
  left: 1247px;
  letter-spacing: 0;
  line-height: 22px;
  position: absolute;
  top: 702px;
  white-space: nowrap;
}

.homepage-desktop .vector-58 {
  height: 11px;
  left: 1224px;
  position: absolute;
  top: 708px;
  width: 15px;
}

.homepage-desktop .kite {
  height: 41px;
  left: 1312px;
  object-fit: cover;
  position: absolute;
  top: 325px;
  width: 34px;
}

.homepage-desktop .vector-59 {
  height: 71px;
  left: 248px;
  position: absolute;
  top: 346px;
  width: 71px;
}

.homepage-desktop .vector-60 {
  height: 58px;
  left: 1620px;
  position: absolute;
  top: 380px;
  width: 34px;
}

.homepage-desktop .vector-61 {
  height: 33px;
  left: 300px;
  position: absolute;
  top: 253px;
  width: 32px;
}

.homepage-desktop .ellipse-9 {
  background-color: #ffd700;
  border-radius: 10.5px;
  height: 21px;
  left: 1587px;
  position: absolute;
  top: 212px;
  width: 21px;
}

.homepage-desktop .vector-62 {
  height: 1px;
  left: 1840px;
  object-fit: cover;
  position: absolute;
  top: 8294px;
  width: 1px;
}

.homepage-desktop .vector-63 {
  height: 1px;
  left: 1841px;
  object-fit: cover;
  position: absolute;
  top: 8294px;
  width: 1px;
}

.homepage-desktop .overlap-16 {
  height: 490px;
  left: 269px;
  position: absolute;
  top: 5196px;
  width: 658px;
}

.homepage-desktop .overlap-17 {
  height: 490px;
  left: 41px;
  position: absolute;
  top: 0;
  width: 617px;
}

.homepage-desktop .vector-64 {
  height: 484px;
  left: 19px;
  position: absolute;
  top: 5px;
  width: 513px;
}

.homepage-desktop .vector-65 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 73px;
  width: 44px;
}

.homepage-desktop .vector-66 {
  height: 51px;
  left: 7px;
  position: absolute;
  top: 314px;
  width: 103px;
}

.homepage-desktop .group-22 {
  height: 98px;
  left: 423px;
  position: absolute;
  top: 0;
  width: 98px;
}

.homepage-desktop .layer-7 {
  height: 265px;
  left: 163px;
  position: absolute;
  top: 45px;
  width: 424px;
}

.homepage-desktop .group-23 {
  height: 42px;
  left: 575px;
  position: absolute;
  top: 119px;
  width: 42px;
}

.homepage-desktop .rectangle-24 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 77px;
  left: 372px;
  position: absolute;
  top: 333px;
  width: 77px;
}

.homepage-desktop .rectangle-25 {
  background-color: #ffffff;
  border-radius: 14px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 107px;
  left: 478px;
  position: absolute;
  top: 383px;
  width: 107px;
}

.homepage-desktop .group-24 {
  height: 75px;
  left: 493px;
  position: absolute;
  top: 399px;
  width: 75px;
}

.homepage-desktop .group-25 {
  height: 49px;
  left: 387px;
  position: absolute;
  top: 348px;
  width: 49px;
}

.homepage-desktop .vector-67 {
  height: 21px;
  left: 64px;
  position: absolute;
  top: 468px;
  width: 152px;
}

.homepage-desktop .frame-17 {
  height: 358px;
  left: 67px;
  position: absolute;
  top: 119px;
  width: 132px;
}

.homepage-desktop .vector-68 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 153px;
  width: 48px;
}

.homepage-desktop .group-26 {
  height: 148px;
  left: 825px;
  position: absolute;
  top: 963px;
  width: 160px;
}

.homepage-desktop .overlap-18 {
  height: 148px;
  position: relative;
}

.homepage-desktop .layer-8 {
  height: 119px;
  left: 0;
  position: absolute;
  top: 0;
  width: 144px;
}

.homepage-desktop .icon-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 69px;
  left: 91px;
  position: absolute;
  top: 79px;
  width: 69px;
}

.homepage-desktop .icon {
  height: 31px;
  left: 19px;
  position: absolute;
  top: 18px;
  width: 31px;
}

.homepage-desktop .group-27 {
  height: 148px;
  left: 1505px;
  position: absolute;
  top: 963px;
  width: 239px;
}

.homepage-desktop .layer-9 {
  height: 109px;
  left: 0;
  position: absolute;
  top: 0;
  width: 209px;
}

.homepage-desktop .group-28 {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 7px 7px 50px #1c7eb526;
  height: 69px;
  left: 170px;
  position: absolute;
  top: 79px;
  width: 69px;
}
